import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Input, Form, Drawer, Button, message, Row, Col, Collapse, Skeleton, Popconfirm, Select, Popover, Typography, Space } from 'antd';
import { PlusOutlined, EditOutlined, QrcodeOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { ApiHelpers, sortByKey, sortByKeyMixed } from '../../../helpers';
import * as ROUTES from '../../../const/routes';
import { Link, useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { appIcon } from '../../../const';
import * as htmlToImage from 'html-to-image';
import axios from 'axios';
import { webGuideENV, webGuideEmbedENV, webGuideLightENV } from '../../../config';
import TablePlacecard from './Tables/TablePlacecard';
import QRCodeTableIndex from './Tables/QRCodeTableIndex';
import { useRecoilState } from 'recoil';
import { activeRestaurantState } from '../../../recoil';
import RestaurantHotspots from './RestaurantHotspots';
import QRCodeCustom from './Tables/QRCodeCustom';
import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';
const { Column } = Table;
const { Panel } = Collapse;
const Search = Input.Search;
const { Option } = Select;
const { Text } = Typography;

function loadImage(src) {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous'); // works for me
    img.crossOrigin = 'anonymous';
    img.addEventListener('load', imageReceived, false);
    img.src = src;
    return img;
}

function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
function imageReceived(downloadedImg) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = downloadedImg.width;
    canvas.height = downloadedImg.height;
    canvas.innerText = downloadedImg.alt;

    context.drawImage(downloadedImg, 0, 0);

    try {
        localStorage.setItem('saved-image-example', canvas.toDataURL('image/png'));
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}
async function getBase64(url) {
    let image = await axios.get(url, { responseType: 'arraybuffer' });
    let raw = Buffer.from(image.data).toString('base64');

    return raw;
    //return 'data:' + image.headers['content-type'] + ';base64,' + raw;
}

async function getLogoUrl(url) {
    const result = await getBase64(url);
    return result;
}

const RestaurantTables = (props) => {
    const [form] = Form.useForm();
    const [restaurantTables, setRestaurantTables] = useState([]);
    const [restaurantTablesFilter, setRestaurantTablesFilter] = useState([]);
    const [restaurantHotspots, setRestaurantHotspots] = useState([]);
    const [newElement, setNewElement] = useState([]);
    const [actionType, setActionType] = useState(props.actionType);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [drawerDownloadVisible, setDrawerDownloadVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [integrationTables, setIntegrationTables] = useState([]);
    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);
    const qrLogo =
        activeRestaurant.subscriptionPlan !== 'Free' && activeRestaurant.logoPath ? ApiHelpers.getImageLink(activeRestaurant.logoPath) : appIcon;
    const refQRCode = useRef(null);
    const refQRCodeLight = useRef(null);
    const navigate = useNavigate();

    const [logoBase64, setLogoBase64] = useState('');

    const [selectedHotspot, setSelectedHotspot] = useState({});

    const isSelfService = activeRestaurant.sysRestaurantType === 'SelfService';

    const INITIAL_COLLAPSE_ACTIVE_KEY = ['info', 'qrCode', 'download'];
    const [collapseActiveKey, setCollapseActiveKey] = useState(INITIAL_COLLAPSE_ACTIVE_KEY);

    const tooltipHotspot = {
        title: '',
        text: (
            <div>
                Iti poti defini una sau mai multe retele Wi-Fi pe care clientii le pot folosi.
                <p>
                    In tab-ul{' '}
                    <a
                        onClick={() => {
                            setDrawerVisible(false);
                            navigate(ROUTES.ADMIN_LOCATIONS + props.restaurantId + ROUTES.ADMIN_RESTAURANT_TABLES + '#wifi');
                        }}>
                        Retele Wi-Fi
                    </a>
                </p>
                <span>Apoi asociezi masa cu reteaua respectiva in functie de semnalul acesteia. </span>
                <p>Detaliile retelei o sa apara in placecard-ul format A6.</p>
            </div>
        ),
    };
    const INITIAL_STATE_ELEMENT = {
        tableIndex: null,
        uniqueIdentificationToken: null,
        restaurantHotspotToken: null,
        restaurantToken: props.restaurantId,
    };
    const handleDownload = (ref, tableIndex, language = 'ro', type = 'standard') => {
        if (ref.current === null) {
            return;
        }
        htmlToImage
            .toPng(ref.current, { cacheBust: true, height: 300, width: 300 })
            .then(function (dataUrl) {
                var img = new Image();

                img.setAttribute('crossOrigin', 'anonymous');
                img.crossOrigin = 'anonymous';
                img.src = dataUrl;

                const link = document.createElement('a');
                link.download =
                    language === 'ro' ? 'qrcode' + (type === 'light' ? '-light' : '') + '-masa-' + tableIndex : 'placecard-table-' + tableIndex;
                link.href = dataUrl;

                link.click();
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };

    useEffect(() => {
        // Get Restaurant tables by Id
        ApiHelpers.getRestaurantTables(props.restaurantId).then(
            (response) => {
                if (!isSelfService) {
                    setRestaurantTables(sortByKeyMixed(response.data, 'tableIndex'));
                    setRestaurantTablesFilter(sortByKeyMixed(response.data, 'tableIndex'));
                } else {
                    setRestaurantTables(
                        sortByKey(
                            response.data.filter((table) => table.qrCodeContent !== null),
                            'tableIndex'
                        )
                    );
                    setRestaurantTablesFilter(
                        sortByKey(
                            response.data.filter((table) => table.qrCodeContent !== null),
                            'tableIndex'
                        )
                    );
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [isSelfService]);

    useEffect(() => {
        // Get Restaurant hotspots
        ApiHelpers.getRestaurantHotspots(props.restaurantId).then(
            (response) => {
                setRestaurantHotspots(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [drawerVisible]);

    useEffect(() => {
        ApiHelpers.getIntegrationTables(props.restaurantId).then(
            (response) => {
                setIntegrationTables(response.data);
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [props.restaurantId]);

    useEffect(() => {
        if (activeRestaurant.subscriptionPlan !== 'Free' && activeRestaurant.logoPath)
            toDataURL(ApiHelpers.getImageLink(activeRestaurant.logoPath), function (dataUrl) {
                //console.log('RESULT:', dataUrl);
                setLogoBase64(dataUrl);
            });
        else setLogoBase64(appIcon);
    }, [activeRestaurant]);

    const onClose = () => {
        setDrawerVisible(false);
        onReset();
    };

    const onReset = () => {
        form.resetFields();
        setCollapseActiveKey(INITIAL_COLLAPSE_ACTIVE_KEY);
        setNewElement([]);
    };

    const onSave = () => {
        // Validate form
        form.validateFields()
            .then(() => {
                if (actionType === 'new') {
                    ApiHelpers.postRestaurantTables(newElement).then(
                        (response) => {
                            const newList = restaurantTables.concat(response.data.restaurantTable);

                            setRestaurantTables(newList);
                            setRestaurantTablesFilter(newList);
                            message.success('Element adaugat!');
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                } else if (actionType === 'edit') {
                    ApiHelpers.updateRestaurantTables(newElement).then(
                        (response) => {
                            const elementsIndex = restaurantTables.findIndex(
                                (element) => element.uniqueIdentificationToken === newElement.uniqueIdentificationToken
                            );
                            let newMenuData = [...restaurantTables];
                            newMenuData[elementsIndex] = { ...newElement };
                            setRestaurantTables(newMenuData);
                            setRestaurantTablesFilter(newMenuData);
                            message.success('Element modificat!');
                        },
                        (error) => {
                            console.log(error);
                            message.error('Eroare!');
                        }
                    );
                }
                onReset();
                setDrawerVisible(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const onDelete = () => {
        ApiHelpers.deleteRestaurantTable(newElement.uniqueIdentificationToken).then(
            (response) => {
                if (response.data.statusCode.statusCode === 200) {
                    const newList = restaurantTables.filter(function (obj) {
                        return obj.uniqueIdentificationToken !== newElement.uniqueIdentificationToken;
                    });
                    setRestaurantTables(newList);
                    setRestaurantTablesFilter(newList);
                    message.success('Elementul a fost sters!');
                }
            },
            (error) => {
                console.log(error);
                message.error('Eroare!');
            }
        );
        onReset();
        setDrawerVisible(false);
    };

    const onChange = (event) => {
        setNewElement({
            ...newElement,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeSelect = (value) => {
        setNewElement({
            ...newElement,
            restaurantHotspotToken: value,
        });
        setSelectedHotspot(restaurantHotspots.filter((d) => d.uniqueIdentificationToken === value)[0]);
    };

    const onChangeSelectIntegration = (value) => {
        setNewElement({
            ...newElement,
            integrationCode: value,
        });
    };

    const onAddElement = () => {
        setActionType('new');
        setCollapseActiveKey(['info']);
        setNewElement({
            ...INITIAL_STATE_ELEMENT,
        });
        setDrawerVisible(true);
    };

    const onEditElement = (record, collapseActiveKey = INITIAL_COLLAPSE_ACTIVE_KEY) => {
        setActionType('edit');
        if (collapseActiveKey) setCollapseActiveKey(collapseActiveKey);

        // Set form fields values
        const editObject = {
            ...record,
        };

        form.setFieldsValue({
            ...editObject,
        });

        // Update state with the element being edited
        setNewElement({ ...editObject });
        setSelectedHotspot(restaurantHotspots.filter((d) => d.uniqueIdentificationToken === record.restaurantHotspotToken)[0]);
        setDrawerVisible(true);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        if (value) {
            setRestaurantTables(
                restaurantTablesFilter.filter((item) => {
                    if (item.tableIndex.toLowerCase().indexOf(value) > -1) return true;
                })
            );
        } else setRestaurantTables(restaurantTablesFilter);
    };

    const generateQRCodeValue = (qrCodeContent, type = 'standard') => {
        if (qrCodeContent) {
            if (type === 'standard') return webGuideENV + qrCodeContent;
            else if (type === 'embed') return webGuideEmbedENV + qrCodeContent;
            else if (type === 'light') return webGuideLightENV + qrCodeContent;
        }
    };

    const handleCollapse = (key) => {
        setCollapseActiveKey(key);
    };

    const downloadQRCodes = () => {
        setDrawerDownloadVisible(true);
    };

    return (
        <>
            {isLoading ? (
                <Skeleton active></Skeleton>
            ) : (
                <>
                    {!isSelfService || (isSelfService && restaurantTables.length == 0) ? (
                        <>
                            <Button type="primary" onClick={() => onAddElement()} className="btnAdd">
                                <PlusOutlined /> Adauga masa
                            </Button>
                        </>
                    ) : null}
                    {activeRestaurant.sysRestaurantType !== 'SelfService' ? (
                        <Search placeholder="Cauta" onChange={handleSearch} className="tableSearchField" />
                    ) : null}
                    <Table dataSource={restaurantTables} pagination={false} rowKey="uniqueIdentificationToken" scroll={{ x: 240 }}>
                        <Column title="Index" width="100px" align="right" dataIndex="tableIndex" />
                        <Column
                            title="Actiuni"
                            key="actions"
                            render={(text, record) => (
                                <>
                                    <Button
                                        type="default"
                                        className="tableAction"
                                        icon={<EditOutlined />}
                                        onClick={() => onEditElement(record, ['info'])}>
                                        Editează
                                    </Button>
                                    <Button
                                        type="default"
                                        className="tableAction"
                                        icon={<QrcodeOutlined />}
                                        onClick={() => onEditElement(record, ['qrCode', 'download'])}>
                                        Vezi cod QR
                                    </Button>
                                </>
                            )}
                        />

                        {!isSelfService ? (
                            <Column
                                title="Status"
                                dataIndex="occupied"
                                render={(text, record) =>
                                    record.occupied === false ? (
                                        <div>
                                            <span className="tableIcon iconFree"></span>Liber
                                        </div>
                                    ) : (
                                        <div>
                                            <span className="tableIcon iconBusy"></span>Ocupat
                                        </div>
                                    )
                                }
                            />
                        ) : null}
                    </Table>
                    <Drawer
                        title={!isSelfService ? 'Masa' : 'Comanda'}
                        width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
                        onClose={onClose}
                        visible={drawerVisible}
                        bodyStyle={{ paddingBottom: 80 }}
                        footer={
                            <div className="modalFooter">
                                <div
                                    style={{
                                        textAlign: 'left',
                                    }}>
                                    {actionType !== 'new' ? (
                                        <Popconfirm title="Sigur vrei sa stergi?" onConfirm={onDelete}>
                                            <Button size="large" danger style={{ marginRight: 8 }}>
                                                Sterge
                                            </Button>
                                        </Popconfirm>
                                    ) : null}
                                </div>
                                <div
                                    style={{
                                        textAlign: 'right',
                                    }}>
                                    <Button size="large" onClick={onClose} style={{ marginRight: 8 }}>
                                        Anuleaza
                                    </Button>
                                    <Button size="large" onClick={onSave} type="primary">
                                        Salveaza
                                    </Button>
                                </div>
                            </div>
                        }>
                        <Form layout="vertical" form={form} size="large">
                            <Collapse className="formCollapse formDrawer" bordered={false} activeKey={collapseActiveKey} onChange={handleCollapse}>
                                <Panel header="Informații" key="info">
                                    <Form.Item name="tableIndex" label="Index masa" rules={[{ required: true, message: 'Camp obligatoriu' }]}>
                                        <Input placeholder="" onBlur={onChange} name="tableIndex" value={newElement.tableIndex} />
                                    </Form.Item>
                                    <Form.Item
                                        name="restaurantHotspotToken"
                                        label={
                                            <>
                                                <span className="labelIcon">Nume retea Wi-Fi </span>
                                                <Popover placement="right" title={tooltipHotspot.title} content={tooltipHotspot.text} trigger="hover">
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            </>
                                        }
                                        rules={[{ required: false, message: 'Camp obligatoriu' }]}>
                                        <Select
                                            size="large"
                                            placeholder="Selecteaza retea"
                                            onChange={onChangeSelect}
                                            name="restaurantHotspotToken"
                                            value={newElement.restaurantHotspotToken}>
                                            {restaurantHotspots.map((d) => (
                                                <Option key={d.uniqueIdentificationToken} value={d.uniqueIdentificationToken}>
                                                    {d.ssid}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Panel>

                                {newElement.qrCodeContent ? (
                                    <>
                                        <Panel header="Placecard" key="download">
                                            {!isSelfService ? (
                                                <QRCodeCustom
                                                    value={generateQRCodeValue(newElement.shortUrl)}
                                                    tableIndex={newElement.tableIndex}
                                                    restaurantId={props.restaurantId}
                                                    selectedHotspot={selectedHotspot}
                                                />
                                            ) : null}
                                            {activeRestaurant.subscriptionPlan === 'Full' ? (
                                                <TablePlacecard
                                                    value={generateQRCodeValue(newElement.qrCodeContent)}
                                                    tableIndex={newElement.tableIndex}
                                                    restaurantId={props.restaurantId}
                                                    selectedHotspot={selectedHotspot}
                                                />
                                            ) : null}
                                        </Panel>
                                        <Panel header="Cod QR" key="qrCode">
                                            <Row>
                                                <Col>
                                                    <div ref={refQRCode}>
                                                        <QRCodeCanvas
                                                            value={generateQRCodeValue(newElement.shortUrl)}
                                                            size={300}
                                                            level={'Q'}
                                                            fgColor="#292333"
                                                            id="qrCodeEl"
                                                            imageSettings={{
                                                                src: logoBase64,
                                                                width: 90,
                                                                height: 90,
                                                                excavate: true,
                                                            }}
                                                        />
                                                    </div>

                                                    <Space size={20}>
                                                        <Button
                                                            onClick={() => handleDownload(refQRCode, newElement.tableIndex, 'ro')}
                                                            icon={<DownloadOutlined />}>
                                                            Descarca
                                                        </Button>
                                                        <a href={generateQRCodeValue(newElement.shortUrl)} target="blank">
                                                            Link meniu digital
                                                        </a>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </>
                                ) : null}
                                {activeRestaurant.subscriptionPlan !== 'Free' && newElement.shortUrl ? (
                                    <Panel header="Cod QR fără comenzi" key="qrCodeLight">
                                        <p>Cu acest cod nu vor fi afișate opțiunile "cheamă ospătar", "cere nota" și "acordă feedback".</p>
                                        {qrLogo ? (
                                            <div ref={refQRCodeLight}>
                                                <QRCodeCanvas
                                                    value={generateQRCodeValue(newElement.shortUrl, 'light')}
                                                    size={300}
                                                    level={'Q'}
                                                    fgColor="#292333"
                                                    imageSettings={{
                                                        src: logoBase64,
                                                        width: 90,
                                                        height: 90,
                                                        excavate: true,
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <Space size={20}>
                                            <Button
                                                onClick={() => handleDownload(refQRCodeLight, newElement.tableIndex, 'ro', 'light')}
                                                icon={<DownloadOutlined />}>
                                                Descarca
                                            </Button>
                                            <a href={generateQRCodeValue(newElement.shortUrl, 'light')} target="blank">
                                                Link meniu digital
                                            </a>
                                        </Space>
                                        <div></div>
                                        <p className="spacingTop">
                                            Cod <strong>embed</strong>: folosiți urmatorul cod pentru a afișa meniul in website-ul dumneavoastra
                                        </p>
                                        <Text code>
                                            {`<iframe
                                                src="${generateQRCodeValue(newElement.shortUrl, 'embed')}"
                                                width="900"
                                                height="800"
                                                style="border:none;"></iframe>`}
                                        </Text>
                                    </Panel>
                                ) : null}
                                {activeRestaurant.activatePosIntegration === true ? (
                                    <Panel header="Integrare POS" key="integrationPOS">
                                        <Form.Item
                                            name="integrationCode"
                                            label={
                                                <>
                                                    <span className="labelIcon">Asociere masa</span>
                                                </>
                                            }
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Camp optional',
                                                },
                                            ]}>
                                            <Select
                                                size="large"
                                                placeholder="Alege"
                                                onChange={onChangeSelectIntegration}
                                                name="integrationCode"
                                                value={newElement.integrationCode}
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {integrationTables.map((d) => {
                                                    return (
                                                        <Option key={'integrationCode-' + d.code} value={d.code}>
                                                            {d.name + (d.zone ? ' (' + d.zone + ')' : '')}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Panel>
                                ) : null}
                            </Collapse>
                        </Form>
                    </Drawer>
                </>
            )}
        </>
    );
};

export default RestaurantTables;
